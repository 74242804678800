
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import UploadFile from "@/components/uploadFiles/UploadFile.vue";
import { useI18n } from "vue-i18n";
import Tree from "@/components/phonebook/Tree.vue";
import ApiService from "@/core/services/ApiService";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";
import {
  checkBotHasActivePlatform,
  checkPackagePlatform,
  getLocale,
} from "@/core/services/HelpersFun";
import platform from "@/components/Platform.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import userInfo from "@/core/services/UserInfo";
export default defineComponent({
  name: "kt-input-numbers-way",
  props: {
    messageId: {
      type: Number,
      default: null,
    },
    botId: {
      type: String,
      default: null,
    },
    platform: {
      type: String,
      default: null,
    },
  },
  components: {
    UploadFile,
    Tree,
    platform,
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const store = useStore();
    const user = userInfo.getUser();
    const AllBotsHolder = AllBots();
    const { botList } = storeToRefs(AllBotsHolder);

    const uniq = (array) => {
      return Array.from(new Set(array));
    };

    const treeRef = ref();
    const phonebooksTree = ref([]);
    const recipients = ref("");

    const dataHolder = reactive({
      bot_id: props.botId,
      platforms: [],
      numbersInputType: "manual",
      recipients: computed(() => {
        return uniq(recipients.value.split("\n"));
      }),
      selectedNumbers: computed(() => {
        return treeRef.value?.selectedNumbers;
      }),
      importExcelFileNumbers: "",
      with_country_code: true,
      country_code: user?.country?.phone_code,
    });

    const selectedBot = computed(() => {
      if (!botList.value) return false;
      return botList.value.find((item) => item.bot_id === dataHolder.bot_id);
    });

    // start related for phone numbers
    const getPhonebooksTree = () => {
      ApiService.query("phonebook/tree", {}).then(({ data }) => {
        phonebooksTree.value = data.data.tree;
      });
    };

    const exelUploadedCallBack = (file) => {
      dataHolder.importExcelFileNumbers = file.path;
    };

    const inputNumberType = ref({
      manual: {
        name: "manual",
        icon: "art/art005.svg",
        title: "",
      },
      phonebook: {
        name: "phonebook",
        icon: "communication/com005.svg",
        title: "",
      },
      excel: {
        name: "excel",
        icon: "files/fil007.svg",
        title: "",
      },
      variable: {
        name: "variable",
        icon: "general/gen005.svg",
        title: "",
      },
    });

    watch(dataHolder, () => {
      emit("getReception", dataHolder);
    });

    onMounted(() => {
      getPhonebooksTree();

      if (botList.value) {
        let firstActiveBot = botList.value.filter(
          (item) =>
            item.platforms.length > 0 &&
            item.platforms.filter((plat) => plat.status === "ready").length > 0
        );
        if (firstActiveBot.length > 0)
          dataHolder.bot_id = firstActiveBot[0].bot_id;
      }
    });
    // end

    const submitButton = ref<HTMLButtonElement | null>(null);
    const addRecipientSubmit = () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.post(`message/${props.messageId}/add-recipients`, {
        platforms: [props.platform],
        numberType: dataHolder.numbersInputType,
        recipients:
          dataHolder.numbersInputType === "manual"
            ? dataHolder.recipients
            : dataHolder.selectedNumbers,
        import_file: dataHolder.importExcelFileNumbers
          ? dataHolder.importExcelFileNumbers
          : undefined,
        with_country_code: dataHolder.with_country_code,
        country_code: dataHolder.with_country_code
          ? user?.country?.phone_code
          : null,
      })
        .then(() => {
          emit("recipientSaved");
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          }
        });
    };

    
    const toEnglishDigits = (nums) => {
      // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
      var e = "۰".charCodeAt(0);
      nums = nums.replace(/[۰-۹]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
      e = "٠".charCodeAt(0);
      nums = nums.replace(/[٠-٩]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      return nums;
      // recipients.value = nums;
    };

    return {
      toEnglishDigits,
      inputNumberType,
      dataHolder,
      translate,
      exelUploadedCallBack,
      treeRef,
      recipients,
      phonebooksTree,

      botList,

      checkBotHasActivePlatform,
      checkPackagePlatform,
      getLocale,
      selectedBot,
      addRecipientSubmit,
      submitButton,
      user,
    };
  },
});
