
import { computed, defineComponent, ref, watch } from "vue";
import { translate } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "Tree",
  props: {
    numbersTree: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const treeRef = ref();
    const properties = ref({
      value: "id",
      label: "name",
      children: "numbers",
    });

    const getCheckedNodes = computed(() => {
      if (!treeRef.value) return [];
      return treeRef.value!.getCheckedNodes(false);
    });

    const selectedNumbers = computed(() => {
      return getCheckedNodes.value.map((item) => item.name);
    });

    watch(selectedNumbers.value, () => {
      emit("getNumbers", selectedNumbers.value);
    });

    return {
      properties,
      treeRef,
      translate,
      selectedNumbers,
      getCheckedNodes,
    };
  },
});
