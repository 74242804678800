import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree_v2 = _resolveComponent("el-tree-v2")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tree_v2, {
      data: _ctx.numbersTree,
      props: _ctx.properties,
      "show-checkbox": "",
      ref: "treeRef",
      class: _normalizeClass([
        'bg-light-success',

        'direction-rtl',
        'border rounded border-dashed border-gray-400',
      ])
    }, null, 8, ["data", "props"])
  ]))
}